import { render, staticRenderFns } from "./regist_home_older.vue?vue&type=template&id=69541732&scoped=true&"
import script from "./regist_home_older.vue?vue&type=script&lang=js&"
export * from "./regist_home_older.vue?vue&type=script&lang=js&"
import style0 from "./regist_home_older.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./regist_home_older.vue?vue&type=style&index=1&id=69541732&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69541732",
  null
  
)

export default component.exports